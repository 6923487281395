import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "kyleBoe",
  "categories": ["news"],
  "date": "2016-07-05",
  "path": "/blog/upgrading-codepen",
  "summary": "Our cofounder Joshua Wood was recently on the weekly CodePen.io podcast to discuss how we helped them upgrade their Rails app.",
  "title": "Upgrading CodePen",
  "image": "./codepen.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@kbiz"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`TLDR: Our cofounder Joshua Wood was `}<a parentName="em" {...{
          "href": "https://blog.codepen.io/2016/06/28/096-upgrading-rails/"
        }}>{`recently on the weekly CodePen.io podcast`}</a>{` to discuss how we helped them upgrade their Rails app:`}</em></p>
    <audio controls="controls" preload="none">
  <source type="audio/mpeg" src="https://media.blubrry.com/codepen_radio/p/codepen-podcast.s3.amazonaws.com/096.mp3?_=1" />
    </audio>
    <br />
    <p><a parentName="p" {...{
        "href": "https://blog.codepen.io/2016/06/28/096-upgrading-rails/"
      }}>{`096:Upgrading Rails`}</a></p>
    <p>{`You may have seen our ad in Ruby Weekly advertising our site `}<a parentName="p" {...{
        "href": "https://www.upgraderails.com/"
      }}>{`UpgradeRails.com`}</a>{`. In response to our ad, the fine folks over at `}<a parentName="p" {...{
        "href": "http://codepen.io/"
      }}>{`CodePen.io`}</a>{` got in touch with us and threw down the gauntlet: upgrade CodePen from Rails 3.2 to Rails 4.2.`}</p>
    <p>{`After analyzing CodePen's codebase and determining that the upgrade was within their budget, we got to work. The end result: we crushed it!`}</p>
    <img {...{
      "src": "https://media.giphy.com/media/fGkCHF0bzRWPm/giphy.gif",
      "alt": "Crushing it gif",
      "title": "Crushing it"
    }}></img>
    <p>{`They were so happy with our work that they invited our very own cofounder and creator of Upgrade Rails, `}<a parentName="p" {...{
        "href": "https://twitter.com/joshuap"
      }}>{`Joshua Wood`}</a>{`, onto their weekly podcast to talk about the upgrade. During the podcast CodePen cofounder Tim Sabat summarized it best:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“This is the perfect thing for us to consult on… we can be building user facing features while `}{`[Hint]`}{` can be working hard in the background.”`}</p>
      <byline>- Tim Sabat</byline>
    </blockquote>
    <p>{`To hear all about the upgrade process, `}<a parentName="p" {...{
        "href": "https://blog.codepen.io/2016/06/28/096-upgrading-rails/"
      }}>{`check out the podcast`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      